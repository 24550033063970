<template>
  <div>
    <!-- 视频资源库 -->
    <!-- <el-tabs v-model="activeName" type="card" tab-position="top" @tab-click="handleClick">
      <el-tab-pane v-for="item in panes" :key="item.id" :label="item.value" :name="item.id">
      
      </el-tab-pane>
    </el-tabs> -->
    <div style="padding:20px 0"> 
        <el-table
          :data="fileList.slice((currentPage-1)*pageSize,currentPage*pageSize)"
          border
          size="mini"
        >
          <el-table-column label="序号" prop="fileId"></el-table-column>
          <el-table-column label="名称" prop="fileName"></el-table-column>
          <el-table-column label="大小" prop="size"></el-table-column>
          <el-table-column label="分辨率">
            <template slot-scope="scope">{{scope.row.uploadWidth}}*{{scope.row.uploadHeight}}</template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="selectedFileVideo(scope.row)">选择</el-button>
              <el-button type="text" @click="delFiles(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row style="margin-top:30px">
          <div style="display:flex;justify-content:space-between">
            <el-pagination
              background
              layout="prev, pager, next"
              :hide-on-single-page="false"
              :total="fileList.length"
              :page-size="pageSize"
              :current-page="currentPage"
              @current-change="handleCurrentChange"
            ></el-pagination>
            <div>
              <el-upload
                style="text-align:center"
                :action="actionUrl"
                accept=".mp4, .flv, .ts, .MP4, .FLV, .TS"
                :headers="headers"
                :data="{type:type,category:category}"
                :show-file-list="false"
                :on-success="handleVideoEditSuccess"
                :before-upload="beforeVideoEditUpload"
              >
                <el-button type="primary" size="mini" icon="el-icon-circle-plus-outline">本地上传</el-button>
              </el-upload>
            </div>
          </div>
        </el-row>
    </div>
  </div>
</template>
<script>
import { getFileApi, delFileApi } from "../api/data";
import Aes from "../utils/aes";
export default {
  data() {
    return {
      //上传头部POST请求头部带token信息验证登录状态
      headers: {
        accessToken: this.$store.state.user.authorization
      },
      currentPage: 1, //当前页
      pageSize: 10, //一页显示多少条
      activeName: "背景视频",
      panes: [
        { id: "全部", value: "全部" },
        { id: "开机视频", value: "开机视频" },
        { id: "背景视频", value: "背景视频" },
        { id: "紧急通知", value: "紧急通知" }
      ],
      //查询条件
      form: {
        type: 1,
        category: "背景视频"
      },
      //所有资源集合
      fileList: []
    };
  },
  props: {
    type: Number,
    category: String
  },
    computed: {
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    }, //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    }
  },
  created() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      getFileApi(this.form).then(res => {
        this.fileList = res.data;
      });
    },
    selectedFileVideo(obj){
        //  this.$store.commit("setFileVideo", obj.filePath);
         this.$emit('callBackPath', obj.filePath);
    },
    //删除事件
    delFiles(obj) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delFileApi({ id: obj.fileId }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      });
    },
    //切换分类
    // handleClick() {
    //   this.form.category = this.activeName;
    //   this.currentPage = 1;
    //   this.initLoad();
    // },
    //上传完成事件
    handleVideoEditSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.$message.success("上传成功");
        this.initLoad();
      } else {
        this.$message.error(result.msg);
      }
       this.initLoad();
    },
    //上传前事件
    beforeVideoEditUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!isLt5M) {
        this.$message.warning("上传视频大小不能超过 50MB!");
      }
      return isLt5M;
    },
    //点击第几页
    handleCurrentChange: function(currentPage) {
      this.currentPage = currentPage;
    }
  }
};
</script>
<style lang='scss'>
</style>
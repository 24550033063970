<template>
  <div>
    <YueMu v-if="placesInfo.tag=='yuemu'"></YueMu>
    <JianYue v-if="placesInfo.tag=='jianyue'"></JianYue>
    <ShiShang v-if="placesInfo.tag=='shishang'"></ShiShang>
    <HuaLi v-if="placesInfo.tag=='huali'"></HuaLi>
  </div>
</template>
<script>
import JianYue from "./boot/JianYue";
import YueMu from "./boot/YueMu";
import ShiShang from "./boot/ShiShang";
import HuaLi from "./boot/HuaLi"
import Aes from "../../utils/aes";
export default {
  components: {
    JianYue,
    YueMu,
    ShiShang,
    HuaLi
  },
  computed: {
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    }
  },
  data() {
    return {};
  },
  created() {}
};
</script>
<style lang='scss'>
</style>